





import { Component, Vue } from 'vue-property-decorator';
import FlowViewer from './flow-viewer.vue';

@Component({
  name: 'FlowRequest',
  components: { FlowViewer }
})
export default class FlowStart extends Vue {
  operation: string = 'task_startup';
  get _taskDataId() {
    return this.$route.query.id;
  }
}
